// ########## USER ACONSTANTS ################
// TEST
export const TEST_DATA_REQUEST = 'TEST_DATA_REQUEST';
export const TEST_DATA_SUCCESS = 'TEST_DATA_SUCCESS';
export const TEST_DATA_FAILURE = 'TEST_DATA_FAILURE';
// LOGIN
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGOUT = 'USER_LOGOUT';
// REGISTER
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE';
// FORGOT PASSWORD
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
// RESET PASSWORD
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
// VERIFY USER
export const USER_VERIFY_REQUEST = 'USER_VERIFY_REQUEST';
export const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS';
export const USER_VERIFY_FAILURE = 'USER_VERIFY_FAILURE';
// RESEND VERIFICATION TOKEN
export const RESEND_TOKEN_REQUEST = 'RESEND_TOKEN_REQUEST';
export const RESEND_TOKEN_SUCCESS = 'RESEND_TOKEN_SUCCESS';
export const RESEND_TOKEN_FAILURE = 'RESEND_TOKEN_FAILURE';
// GET USER PROFILE
export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE';
export const USER_PROFILE_RESET = 'USER_PROFILE_RESET';
// EDIT USER PROFILE
export const EDIT_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAILURE = 'EDIT_PROFILE_FAILURE';
// DELETE USER PROFILE
export const DELETE_PROFILE_REQUEST = 'DELETE_PROFILE_REQUEST';
export const DELETE_PROFILE_SUCCESS = 'DELETE_PROFILE_SUCCESS';
export const DELETE_PROFILE_FAILURE = 'DELETE_PROFILE_FAILURE';

// ########## SOCIAL/CONTACTS CONSTANST ################
export const ADD_SOCIALLINKS_REQUEST = 'ADD_SOCIALLINKS_REQUEST';
export const ADD_SOCIALLINKS_SUCCESS = 'ADD_SOCIALLINKS_SUCCESS';
export const ADD_SOCIALLINKS_FAILURE = 'ADD_SOCIALLINKS_FAILURE';

export const USER_SOCIALLINKS_REQUEST = 'USER_SOCIALLINKS_REQUEST';
export const USER_SOCIALLINKS_SUCCESS = 'USER_SOCIALLINKS_SUCCESS';
export const USER_SOCIALLINKS_FAILURE = 'USER_SOCIALLINKS_FAILURE';

export const EDIT_SOCIALLINKS_REQUEST = 'EDIT_SOCIALLINKS_REQUEST';
export const EDIT_SOCIALLINKS_SUCCESS = 'EDIT_SOCIALLINKS_SUCCESS';
export const EDIT_SOCIALLINKS_FAILURE = 'EDIT_SOCIALLINKS_FAILURE';

export const DELETE_SOCIALLINKS_REQUEST = 'DELETE_SOCIALLINKS_REQUEST';
export const DELETE_SOCIALLINKS_SUCCESS = 'DELETE_SOCIALLINKS_SUCCESS';
export const DELETE_SOCIALLINKS_FAILURE = 'DELETE_SOCIALLINKS_FAILURE';

// ########## CREATIVE/MUSIC CONSTANST ################
export const ADD_CREATIVELINKS_REQUEST = 'ADD_CREATIVELINKS_REQUEST';
export const ADD_CREATIVELINKS_SUCCESS = 'ADD_CREATIVELINKS_SUCCESS';
export const ADD_CREATIVELINKS_FAILURE = 'ADD_CREATIVELINKS_FAILURE';

export const USER_CREATIVELINKS_REQUEST = 'USER_CREATIVELINKS_REQUEST';
export const USER_CREATIVELINKS_SUCCESS = 'USER_CREATIVELINKS_SUCCESS';
export const USER_CREATIVELINKS_FAILURE = 'USER_CREATIVELINKS_FAILURE';

export const EDIT_CREATIVELINKS_REQUEST = 'EDIT_CREATIVELINKS_REQUEST';
export const EDIT_CREATIVELINKS_SUCCESS = 'EDIT_CREATIVELINKS_SUCCESS';
export const EDIT_CREATIVELINKS_FAILURE = 'EDIT_CREATIVELINKS_FAILURE';

export const DELETE_CREATIVELINKS_REQUEST = 'DELETE_CREATIVELINKS_REQUEST';
export const DELETE_CREATIVELINKS_SUCCESS = 'DELETE_CREATIVELINKS_SUCCESS';
export const DELETE_CREATIVELINKS_FAILURE = 'DELETE_CREATIVELINKS_FAILURE';